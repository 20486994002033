import React from "react";

const Footer = () => (
  <footer
    className="text-white text-center py-4"
    style={{
      backgroundColor: "#000000", // Mesma cor do navbar
    }}
  >
    <p>© 2024 WaappTv. Todos os direitos reservados.</p>
  </footer>
);

export default Footer;
