import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQRevendas = () => (
  <section id="faq-revendas" className="py-5 bg-light">
    <div className="container">
      <h2 className="fw-bold text-success mb-4 text-center">FAQ - Perguntas e Respostas </h2>
      <Accordion>
        {/* Pergunta 1 */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Como me torno um revendedor?</Accordion.Header>
          <Accordion.Body>
            Para se tornar um revendedor, basta adquirir um dos nossos pacotes de créditos e começar a oferecer nossos serviços aos seus clientes. Nosso suporte estará disponível para ajudá-lo no processo.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 2 */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Qual é o investimento inicial?</Accordion.Header>
          <Accordion.Body>
            O investimento inicial depende do pacote de créditos escolhido. O menor pacote começa com 10 créditos. Consulte nossa tabela de preços para mais detalhes.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 3 */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>O que está incluso no painel do revendedor?</Accordion.Header>
          <Accordion.Body>
            O painel do revendedor inclui ferramentas para gerenciar clientes, gerar testes, acompanhar vendas, e suporte direto com nossa equipe. Além disso, você poderá personalizar sua oferta de serviços.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 4 */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>Como funciona a geração de testes?</Accordion.Header>
          <Accordion.Body>
            Revendedores podem gerar testes ilimitados para atrair novos clientes. Cada teste tem duração de 4 horas e não gera custos adicionais.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 5 */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>Quais são as regras para revendedores?</Accordion.Header>
          <Accordion.Body>
            Revendedores devem respeitar o preço mínimo de R$30 para seus clientes e seguir as políticas da nossa plataforma para evitar bloqueios. O uso inadequado do painel pode resultar na suspensão da conta.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 6 */}
        <Accordion.Item eventKey="5">
          <Accordion.Header>Há suporte exclusivo para revendedores?</Accordion.Header>
          <Accordion.Body>
            Sim, oferecemos suporte exclusivo para revendedores, incluindo um grupo no WhatsApp e atendimento direto com nossa equipe para garantir o sucesso do seu negócio.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 7 */}
        <Accordion.Item eventKey="6">
          <Accordion.Header>Posso revender para outros países?</Accordion.Header>
          <Accordion.Body>
            Sim, nossos serviços podem ser revendidos para clientes em outros países, desde que você respeite as normas locais e a política da nossa plataforma.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 8 */}
        <Accordion.Item eventKey="7">
          <Accordion.Header>Quais formas de pagamento posso aceitar dos meus clientes?</Accordion.Header>
          <Accordion.Body>
            Você pode aceitar qualquer forma de pagamento que preferir dos seus clientes. Internamente, você realiza os pagamentos à nossa plataforma via Pix, Cartão ou Boleto.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 9 */}
        <Accordion.Item eventKey="8">
          <Accordion.Header>Posso cancelar ou reembolsar créditos comprados?</Accordion.Header>
          <Accordion.Body>
            Créditos comprados não são reembolsáveis. No entanto, você pode usá-los sem prazo de validade e para qualquer cliente, garantindo a flexibilidade no uso.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 10 */}
        <Accordion.Item eventKey="9">
          <Accordion.Header>Quais vantagens terei como revendedor?</Accordion.Header>
          <Accordion.Body>
            Como revendedor, você terá acesso a preços competitivos, suporte dedicado, e ferramentas avançadas para gerenciar seus clientes e expandir seu negócio. Nosso sistema é otimizado para facilitar seu sucesso.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  </section>
);

export default FAQRevendas;
