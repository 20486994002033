import React from "react";
import Header from "../components/Header"; // Reutiliza o Header da LP

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <section id="privacy-policy" className="py-5 bg-light">
        <div className="container">
          <div className="text-center mb-5">
            {/* Garante que o título principal tenha texto */}
            <h1 className="fw-bold text-success">
            </h1>
            <p className="text-muted fs-5">
            </p>
          </div>

          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="fw-bold text-success mb-3">Informações que Coletamos</h2>
              <p className="text-muted fs-5">
                Coletamos informações pessoais, como nome, endereço de e-mail e dados de navegação,
                para melhorar sua experiência em nosso site e oferecer serviços personalizados.
              </p>

              <h2 className="fw-bold text-success mt-4 mb-3">Uso das Informações</h2>
              <p className="text-muted fs-5">
                Usamos as informações coletadas para fornecer nossos serviços, melhorar o site e
                enviar comunicações relacionadas às suas preferências.
              </p>

              <h2 className="fw-bold text-success mt-4 mb-3">Cookies</h2>
              <p className="text-muted fs-5">
                Utilizamos cookies para otimizar a experiência do usuário. Você pode gerenciar ou
                desativar os cookies nas configurações do navegador.
              </p>

              <h2 className="fw-bold text-success mt-4 mb-3">Alterações na Política</h2>
              <p className="text-muted fs-5">
                Esta política pode ser atualizada periodicamente. Recomendamos que você revise
                regularmente esta página para estar ciente de quaisquer mudanças.
              </p>

              <p className="text-muted fs-5">
                Se você tiver dúvidas sobre esta política, entre em contato conosco em:
                <strong> contato@waapptv.online</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
