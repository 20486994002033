import React, { useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Planos from "./components/Planos";
import PlanosRevemda from "./components/PlanosRevemda";
import Revenda from "./components/Revenda";
import FAQ from "./components/FAQ";
import FAQREV from "./components/FAQ-REV";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import RequestTestPage from "./pages/RequestTestPage";

function App() {
  const recaptchaRef = useRef(null);

  const handleRecaptcha = async (action) => {
    const token = await recaptchaRef.current.executeAsync(action);
    console.log("reCAPTCHA token:", token);

    // fetch('/api/validate-recaptcha', { method: 'POST', body: JSON.stringify({ token }) });
  };

  return (
    <Router>
      {/* Widget invisível do reCAPTCHA */}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Ldf8F0nAAAAAFJ3LWhlIERjPRzrvxE3v4ALtd4v" // Substitua pela sua chave do site
        size="invisible"
      />

      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Features />
              <Planos />
              <FAQ />
              <Revenda />
              <PlanosRevemda />
              <FAQREV />
            </>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/solicitar-teste" element={<RequestTestPage />} /> */}
      </Routes>
      <Footer />

      {/* Teste de reCAPTCHA global (pode ser removido) */}
      {/* <button
        className="btn btn-primary"
        onClick={() => handleRecaptcha("action_test")}
      >
        Testar reCAPTCHA
      </button> */}
    </Router>
  );
}

export default App;
