import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules"; // Importação corrigida

const PlanosRevenda = () => {
  const whatsappBaseURL = "https://wa.me/551231990322";

  const generateMessage = (planName, price) =>
    encodeURIComponent(
      `Olá, quero ser um revendedor de sucesso e estou interessado no plano "${planName}" com o valor de ${price}.`
    );

  const planos = [
    {
      title: "10 Créditos Revenda",
      subtitle: "Painel WPlay",
      unitPrice: "R$11",
      total: "R$110",
      messagePrice: "R$110.00",
    },
    {
      title: "30 Créditos de Revenda",
      subtitle: "Painel Wplay",
      unitPrice: "R$10",
      total: "300.00",
      messagePrice: "300.00",
    },

    {
      title: "50 Créditos Revenda",
      subtitle: "Painel WPlay MASTER",
      unitPrice: "R$8,00",
      total: "R$400,00",
      messagePrice: "R$400.00",
    },

    {
      title: "100 Créditos Revenda",
      subtitle: "Painel WPlay MASTER",
      unitPrice: "R$7,00",
      total: "R$700,00",
      messagePrice: "R$700.00",
    },

    {
      title: "500 Créditos Revenda",
      subtitle: "Painel WPlay MASTER",
      unitPrice: "R$6,00",
      total: "R$3000,00",
      messagePrice: "R$3.000,00",
    },
    {
      title: "1000 Créditos Revenda",
      subtitle: "Painel WPlay",
      unitPrice: "R$ 5,50",
      total: "R$5.500,00",
      messagePrice: "R$5.500,00",
    },
    
  ];

  return (
    <section id="planos" className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="mb-4 fw-bold">Tudo isso para você ter sucesso na revenda!</h2>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {planos.map((plano, index) => (
            <SwiperSlide key={index}>
              <div className="card shadow border-0 h-100">
                <div className="card-body">
                  <h5 className="text-success fw-bold">{plano.title}</h5>
                  <p className="text-muted">{plano.subtitle}</p>
                  <h3 className="fw-bold text-success">
                    {plano.unitPrice}
                    <span style={{ fontSize: "1rem" }}>,00</span>
                  </h3>
                  <p className="text-muted">Preço Unitário</p>
                  <hr />
                  <p className="fw-bold">Total: {plano.total}</p>
                  <a
                    href={`${whatsappBaseURL}?text=${generateMessage(
                      plano.title,
                      plano.messagePrice
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-success btn-lg mt-3 w-100"
                  >
                    Quero Revender
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default PlanosRevenda;
